<template>
  <div>
    <div class="title">
      <div>
        <span style="margin-right:20px">市场筛选</span>
        <el-select
          v-model="search.market_id"
          clearable
          placeholder="请选择市场"
        >
          <el-option
            v-for="item in market"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="marketid(item.id)"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="margin-right:20px">商户筛选</span>
        <el-select
          v-model="search.seller_id"
          clearable
          placeholder="请选择商户"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button @click="get_evaluate" type="primary">筛 选</el-button>
      <!-- <div>
        <span style="margin-right:20px">评价类目</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
    </div>
    <el-table
      :data="tableData.data"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      border
      style="width: 100%;margin-top:20px"
    >
      <el-table-column prop="market_name" label="市场" width="200">
      </el-table-column>
      <el-table-column prop="seller_name" label="商户" width="200">
      </el-table-column>
      <el-table-column label="评价星级" width="250">
        <template slot-scope="scope">
          <div class="evaluate">
            <span>商家服务</span>
            <el-rate
              v-model="scope.row.service_level"
              disabled
              text-color="#ff9900"
            >
            </el-rate>
          </div>
          <div class="evaluate">
            <span>商品质量</span>
            <el-rate
              v-model="scope.row.quality_level"
              disabled
              text-color="#ff9900"
            >
            </el-rate>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="评价内容">
        <template slot-scope="scope">
          <div class="betten">
            <div class="name">{{ scope.row.con_name }}:</div>
            <div class="contentList">{{ scope.row.result }}</div>
          </div>
          <div class="betten">
            <div class="name">我：</div>
            <div class="contentList">{{ scope.row.reply }}</div>
          </div>
          <div
            style="display: flex; align-items: center;justify-content: space-around;"
          >
            <el-link type="primary">更多</el-link>
            <el-button size="mini" @click="evaluate(scope.row)" round
              >回复</el-button
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="con_name" width="200" label="评价人">
      </el-table-column>
      <el-table-column prop="date" width="250" label="评价时间">
      </el-table-column>
      <el-table-column width="250" label="操作">
        <template slot-scope="scope">
          <div class="hoverc">
            <el-popover
              v-if="scope.row.status * 1 === 1"
              placement="top"
              width="200"
              trigger="hover"
              content="隐藏后手机端就看不到了哦"
            >
              <el-button slot="reference" @click="show(scope.row.id)"
                >隐 藏</el-button
              >
            </el-popover>
            <el-popover
              placement="top"
              width="200"
              trigger="hover"
              content="删除后本列表就看不到了哦"
            >
              <el-button
                type="danger"
                slot="reference"
                @click="isShow(scope.row.id)"
                >删 除</el-button
              >
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="dialog.name"
      :visible.sync="centerDialogVisible"
      width="40%"
      center
    >
      <div class="content">
        <div class="content-list">
          <div class="left" v-show="dialog.date">
            <h3>{{ dialog.name }}</h3>
            <div style="margin:5px 0 0 30px">
              <div style="margin-bottom:10px">{{ dialog.result }}</div>
              <div style="margin-bottom:10px" class="img">
                <img
                  v-for="(item, index) in dialog.picture"
                  :key="index"
                  :src="item"
                  style="width:100px;height:50px;margin:0px 10px 0 0"
                  alt=""
                />
              </div>
              <div>时间：{{ dialog.date }}</div>
            </div>
          </div>
          <div class="right" v-show="dialog.reply_date">
            <h3>我</h3>
            <div class="contents">
              <div style="margin-bottom:10px">{{ dialog.reply }}</div>
              <div>时间：{{ dialog.reply_date }}</div>
            </div>
          </div>
        </div>
        <div class="content-bottom">
          <el-input
            type="textarea"
            placeholder="请输入内容(只能回复一次哦)"
            v-model="dialog.input"
            style="width:80%"
            resize="none"
            ref="input"
            :autosize="{ minRows: 1, maxRows: 3 }"
            @keydown.enter.native="enterMsg"
          >
          </el-input>
          <i
            style="font-size:40px;height:40px;"
            class="el-icon-circle-plus-outline"
          ></i>
          <el-button @click="writeBack" style="height:40px">回 复</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getevaluate,
  POSTevaluateReply,
  POSTevaluateDeal
} from '../../../api/evaluateList'
import { getmarket } from '../../../api/layout'
import { getSellerByMarketId } from '../../../api/productList'
export default {
  name: 'evaluateList',
  data () {
    return {
      search: {
        page: '',
        limit: '',
        market_id: '',
        seller_id: ''
      },
      options: [], // 商户筛选
      market: '', // 市场筛选数据
      dialog: {
        name: '',
        result: '',
        picture: '',
        date: '',
        reply: '',
        reply_date: '',
        input: '',
        id: ''
      },
      centerDialogVisible: false,
      tableData: '' // 表格数据
    }
  },
  methods: {
    // 表格数据
    get_evaluate () {
      getevaluate(this.search).then(res => {
        this.tableData = res.data
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.search.limit = val
      this.get_evaluate()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.search.page = val
      this.get_evaluate()
    },
    // 市场筛选数据
    get_market () {
      getmarket().then(res => {
        this.market = res.data
      })
    },
    // 隐藏
    show (id) {
      POSTevaluateDeal({ id, status: 2 })
        .then(res => {
          this.$message.success(`${res.data}`)
          this.get_evaluate()
        })
        .catch(err => {
          this.$message.error(`${err.msg}`)
        })
    },
    // 删除
    isShow (id) {
      POSTevaluateDeal({ id, status: 1 })
        .then(res => {
          this.$message.success(`${res.data}`)
          this.get_evaluate()
        })
        .catch(err => {
          this.$message.error(`${err.msg}`)
        })
    },
    // 表格里的回复按钮（弹框）
    evaluate (row) {
      this.dialog.id = row.id
      this.dialog.name = row.con_name ? row.con_name : '未知姓名'
      this.dialog.result = row.result
      this.dialog.picture = row.picture
      this.dialog.date = row.date
      this.dialog.reply = row.reply
      this.dialog.reply_date = row.reply_date
      this.centerDialogVisible = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    // 按键回复失去焦点
    enterMsg () {
      this.$refs.input.blur()
      this.writeBack()
    },
    // 回复消息
    writeBack () {
      const data = {
        id: this.dialog.id,
        reply: this.dialog.input
      }
      POSTevaluateReply(data)
        .then(res => {
          this.$message.success(`${res.data}`)
          this.clear()
          this.centerDialogVisible = false
          this.get_evaluate()
        })
        .catch(err => {
          this.$message.error(`${err.msg}`)
        })
    },
    // 清空
    clear () {
      this.dialog = {
        name: '',
        result: '',
        picture: '',
        date: '',
        reply: '',
        reply_date: '',
        input: '',
        id: ''
      }
    },
    // 选择市场后
    marketid (id) {
      getSellerByMarketId(id).then(res => {
        this.options = res.data
      })
    }
  },
  mounted () {
    this.get_evaluate()
    this.get_market()
  }
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.evaluate {
  display: flex;
  align-items: center;
}
.betten {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .name {
    text-align: right;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .contentList {
    text-align: left;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.hoverc {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
/deep/.el-rate__icon {
  font-size: 25px;
}
.content {
  width: 100%;
  height: 500px;
  position: relative;
}
/deep/ .el-dialog__body {
  padding: 0;
}
.content-bottom {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  position: absolute;
  bottom: 10px;
}
.content-list {
  height: 400px;
  background: #f1ededd5;
  padding: 20px;
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
    margin-top: 20px;
    .contents {
      margin: 5px 30px 0 0px;
    }
  }
  .img {
    display: flex;
  }
}
/deep/ .el-textarea__inner {
  min-height: 40px !important;
}
.footers {
  text-align: right;
  margin-top: 10px;
}
/deep/ .el-rate {
  height: 25px;
}
</style>
